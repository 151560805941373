import { FillPrevArrow, FillNextArrow } from "@components/arrows/post-slider";

import type { Post, GroupStoriesType } from "@src/types/index.interface";

import type { Settings } from "react-slick";


export const getSettings = (options: Partial<Settings> = {}) => {
  const defaultOptions: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <FillNextArrow />,
    prevArrow: <FillPrevArrow />,
    responsive: [
      {
        breakpoint: 1215,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 966,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 690,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    ...options,
  };

  return defaultOptions;
};

export const handleSlide = (direction: "left" | "right") => {
  const slider = document.getElementsByClassName("carousel-body")[0];
  if (direction === "left") slider.scrollBy(-400, 0);
  else slider.scrollBy(400, 0);
};

export const groupStories = (posts: Post[]) => {
  const stories: GroupStoriesType = new Map();

  for (const post of posts) {
    if (post.stories && post.stories.length) {
      stories.set(post.id, post.stories);
    }
  }
  return stories;
};

export const getStoryKey = (key: number, stories: GroupStoriesType) =>
  Array.from(stories.keys())[key];

export const filterPreviousPosts = (
  allPosts: Post[],
  selectedPostId: string
): Post[] => {
  const selectedPostIndex = allPosts.findIndex(
    (post) => post.id === selectedPostId
  );

  if (selectedPostIndex === -1) {
    return [];
  }

  return allPosts.slice(selectedPostIndex);
};

export const getPostById = (allPosts: Post[], selectedPostId: string): Post => {
  return allPosts.filter((post) => post.id === selectedPostId)[0];
};

export const posts: Post[] = [
  {
    id: "1",
    title: "Biomarcadores, desabasto de medicamentos",
    primary_image: "carton_trayenta_marzo_mx.jpg",
    secondary_image: "carton_icydm2.jpg",
    created_at: "2024-01-11 19:31:09",
    stories: [
      {
        id: "2",
        playback_id: "dS6UvPbn6hw7kmpg02mIgO45Wv2LfxEK8uLctmKnj02Ps",
        created_at: "2023-12-08 15:17:44",
        duration: 10,
      },
      {
        id: "7",
        playback_id: "y01KVlUaRHvXPlztoC7jYJiG171m9wmEjMr8Tz02FKiW8",
        created_at: "2023-12-08 15:17:45",
        duration: 10,
      },
      {
        
        id: "108",
        playback_id: "mST14f67fyvekGj02u010001R8OArAHE4aA5zh2xPv202hgs",
        created_at: "2024-01-11 19:31:37",
        duration: 9,
      },
    ],
  },
  {
    id: "46",
    title: "El impacto que ha tenido la pandemia por Covid19",
    primary_image: "carton_trayenta_aniversario.jpg",
    secondary_image: "carton_icem_2021.jpg",
    created_at: "2024-01-11 19:31:09",
    stories: [
      {
        
        id: "8",
        playback_id: "dS6UvPbn6hw7kmpg02mIgO45Wv2LfxEK8uLctmKnj02Ps",
        created_at: "2023-10-08 15:17:44",
        duration: 10,
      },
      {
        
        id: "9",
        playback_id: "y01KVlUaRHvXPlztoC7jYJiG171m9wmEjMr8Tz02FKiW8",
        created_at: "2023-10-08 15:17:45",
        duration: 10,
      },
      {
        
        id: "10",
        playback_id: "mST14f67fyvekGj02u010001R8OArAHE4aA5zh2xPv202hgs",
        created_at: "2023-11-08 15:17:45",
        duration: 28,
      },
    ],
  },
  {
    id: "47",
    title: "Desmificando la inmunoterapia y visión del oncólogo",
    primary_image: "carton_trayenta_2021.jpg",
    secondary_image: "carton_icc_2021.jpg",
    created_at: "2024-01-11 19:31:09",
    stories: [
      {
        
        id: "11",
        playback_id: "pxpcVOQYKB01urj02JcNNflakrUzKMB2aSQ00m88pMlTlU",
        created_at: "2023-12-08 15:17:45",
        duration: 9,
      },
      {
        
        id: "12",
        playback_id: "mST14f67fyvekGj02u010001R8OArAHE4aA5zh2xPv202hgs",
        created_at: "2023-12-08 15:17:45",
        duration: 10,
      },
      {
        
        id: "13",
        playback_id: "y01KVlUaRHvXPlztoC7jYJiG171m9wmEjMr8Tz02FKiW8",
        created_at: "2023-12-08 15:17:45",
        duration: 34,
      },
    ],
  },
  {
    id: "48",
    title: "Inteligencia Artificial en oncología",
    primary_image: "carton_sesion_solafire.jpg",
    secondary_image: "carton_fonae_new.jpg",
    created_at: "2024-01-11 19:31:09",
    stories: [
      {
        
        id: "14",
        playback_id: "dS6UvPbn6hw7kmpg02mIgO45Wv2LfxEK8uLctmKnj02Ps",
        created_at: "2023-12-08 15:17:45",
        duration: 21,
      },
      {
        
        id: "15",
        playback_id: "y01KVlUaRHvXPlztoC7jYJiG171m9wmEjMr8Tz02FKiW8",
        created_at: "2023-12-08 15:17:45",
        duration: 28,
      },
      {
        
        id: "16",
        playback_id: "pxpcVOQYKB01urj02JcNNflakrUzKMB2aSQ00m88pMlTlU",
        created_at: "2023-11-08 15:17:44",
        duration: 9,
      },
    ],
  },
  {
    id: "49",
    title: "Inhibidores de ciclina, consejos en la consulta",
    primary_image: "carton_lanzamiento_vitamedica.jpg",
    secondary_image: "carton_expertimss.jpg",
    created_at: "2024-01-11 19:31:09",
    stories: [
      {
        
        id: "17",
        playback_id: "mST14f67fyvekGj02u010001R8OArAHE4aA5zh2xPv202hgs",
        created_at: "2023-12-08 15:17:44",
        duration: 10,
      },
      {
        
        id: "18",
        playback_id: "y01KVlUaRHvXPlztoC7jYJiG171m9wmEjMr8Tz02FKiW8",
        created_at: "2023-12-08 15:17:45",
        duration: 10,
      },
      {
        
        id: "19",
        playback_id: "dS6UvPbn6hw7kmpg02mIgO45Wv2LfxEK8uLctmKnj02Ps",
        created_at: "2023-12-08 15:17:44",
        duration: 34,
      },
    ],
  },
];
