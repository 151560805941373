import { useEffect, useState } from "react";
import Head from "next/head";
import Header from "../layout/header";
import Footer from "../footer";
import DropdownPanelDesktop from "../menu/dropdownPanelDesktop";
import DropdownPanelMobile from "../menu/dropdownPanelMobile";
import LogoutButton from "../auth/logoutButton";
import Zendesk from "react-zendesk";
import api from "../../src/api/index.js";
import { useRouter } from "next/router";
import type { Session, LiveEvent, User } from "../../src/types/index.interface";
import { filter, capitalizeFirstLetter } from "../../src/utils";

import { handleVisitCookie } from "@src/utilities/cookies/functions";

import StoriesModal from "@components/stories/modal";

// import { Inter } from "next/font/google";

// const interFont = Inter({
//   weight: ["300", "400", "500", "600", "700", "800"],
//   subsets: ["latin"],
// });

const ZENDESK_KEY = process.env.ZENDESK_KEY;
const VALUE_ENV = process.env.VALUE_ENV;

interface IProps {
  children: JSX.Element | JSX.Element[];
  session?: Session;
  chat?: boolean | null;
  hideFooter?: boolean;
  pageTitle?: string;
  pageImageUrl?: string;
  pageDescription?: string;
  isArticle?: boolean;
}

var showPrescribingLink = null;
var userData2 = null;

const Layout: React.FC<IProps> = ({
  children,
  session,
  chat = false,
  hideFooter = false,
  pageTitle = "Boehringer Click",
  pageImageUrl,
  pageDescription = "Boehringer Click. Educación médica al alcance de un click.",
  isArticle,
}) => {
  const [liveEvents, setLiveEvents] = useState<LiveEvent[] | []>([]);
  const [userData, setUserData] = useState<User | null>(null);

  const router = useRouter();
  const { asPath } = useRouter();

  const filterLiveEvents = async (
    liveEvents: LiveEvent
  ): Promise<Array<LiveEvent>> => {
    const result = await filter(liveEvents, async ({ id: eventID }) => {
      let isEventInWhiteList = await (
        new api.IsEventInWhiteList(eventID) as any
      ).doQuery(null, session.user.image.access_token);
      return isEventInWhiteList;
    });
    return result;
  };

  const getLiveEvents = async (): Promise<LiveEvent> => {
    if (router.asPath !== "/home") return;

    let liveEvents = await (new api.GetLiveEvents() as any).doQuery(
      null,
      session.user.image.access_token
    );
    liveEvents = await filterLiveEvents(liveEvents);
    setLiveEvents(liveEvents);
  };

  const getPrescribingLink = async () => {
    if (!session) return;
    const prescribinglink = await (
      new api.ShowPrescribingLink(session.user.image.user_id) as any
    ).doQuery();
    showPrescribingLink = prescribinglink === null ? false : true;
  };

  const setCookie = async () => {
    if (!session) return;
    const cookies = await handleVisitCookie(session.user.image.access_token);
    cookies();
  };

  const getUserData = async () => {
    if (userData2) {
      setUserData(userData2);
    }

    if (!userData2) {
      const user = await (
        new api.GetInstatagUser(session.user.image.user_id) as any
      ).doQuery();
      userData2 = user;
      setUserData(userData2);
      console.log("🚽 called GetInstatagUser");
    }
  };

  useEffect(() => {
    getLiveEvents();
  }, []);

  useEffect(() => {
    if (showPrescribingLink !== null) return;
    getPrescribingLink();
  }, []);

  useEffect(() => {
    setCookie();
  }, []);

  useEffect(() => {
    if (!session) {
      return;
    }
    getUserData();
  }, []);

  const setting = {
    color: {
      theme: "#00e47c",
    },
    webWidget: {
      setLocale: "es",
      zIndex: 19,
    },
  };

  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no"
          name="viewport"
        />
        <meta charSet="utf-8" />
        <link rel="icon" type="image/png" href="/img/favicon.png" />

        {/* <script src="https://addevent.com/libs/atc/1.6.1/atc.min.js" defer></script> */}
        <script
          type="text/javascript"
          src={`${process.env.NEXTAUTH_URL}/js/atc.min.js`}
          async
          defer
        ></script>
        {/* <script src="https://embed.typeform.com/embed.js"></script> */}

        {/* <link rel="stylesheet" href="https://rsms.me/inter/inter.css" /> */}

        {/* <script src="http://p3.3playmedia.com/p3sdk.1.10.7.js"></script> */}
        {/* <script src={`${process.env.NEXTAUTH_URL}/js/test.js`}></script> */}

        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />

        {/* <script src="https://cdn.jsdelivr.net/npm/choices.js@9.0.1/public/assets/scripts/choices.min.js"></script>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/choices.js@9.0.1/public/assets/styles/choices.min.css" /> */}

        <title>{`${capitalizeFirstLetter(
          pageTitle
        )} | Boehringer Click`}</title>
        {
          // (VALUE_ENV === 'development' || VALUE_ENV === 'local') &&
          // <script
          //     async
          //     src={"https://script.bi-instatag.com?ref=" + encodeURIComponent(url)}
          // ></script>
        }

        {VALUE_ENV === "production" && (
          <script async data-api="/_hive" src="/bee.js"></script>
        )}

        <meta
          property="og:title"
          content={`${capitalizeFirstLetter(pageTitle)} | Boehringer Click`}
        />

        <meta property="og:url" content={process.env.NEXTAUTH_URL + asPath} />
        <link rel="canonical" href={process.env.NEXTAUTH_URL + asPath} />

        {pageImageUrl && (
          <>
            <meta
              property="og:image"
              content={pageImageUrl + "?w=1200&h=675"}
            />

            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="675" />
          </>
        )}

        <meta property="og:description" content={pageDescription} />
        <meta name="description" content={pageDescription} />

        <meta property="og:type" content={isArticle ? "article" : "website"} />
        <meta property="og:site_name" content="Boehringer Click" />
        <meta property="og:locale" content="es_MX" />
      </Head>

      {/* @ts-ignore */}
      <Zendesk
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={() => console.log("Zendesk is loaded")}
      />

      {/* <div className={`${interFont.className}`}> */}
      <div className="font-boehringer_forward_head">
        {session && (
          <Header session={session} liveEvents={liveEvents}>
            <DropdownPanelDesktop
              key="DropdownPanelDesktop"
              showPrescribingLink={showPrescribingLink}
              session={session}
            >
              <LogoutButton session={session} chat={chat} />
            </DropdownPanelDesktop>

            <DropdownPanelMobile
              key="DropdownPanelMobile"
              showPrescribingLink={showPrescribingLink}
              session={session}
            >
              <LogoutButton session={session} />
            </DropdownPanelMobile>
          </Header>
        )}

        {session && userData && (
          <div
            className="hidden instatag-login"
            data-it-logged-in="true"
            data-it-auth-method="email"
            data-it-visitor-type={`HCP ${userData?.specialty?.name}`}
            data-it-uid={`${userData?.id}`}
            data-it-uid-type="UserID"
          >
            User Successfully Logged In
          </div>
        )}

        <div
          className={`bg-gray-100 antialiased overflow-x-hidden ${
            session ? "mt-16 md:mt-28" : ""
          }`}
          style={{
            backgroundImage: router.pathname.includes("/casos/[slug]")
              ? "url(/img/bg-cases.jpg)"
              : "none",
          }}
        >
          {children}

          <StoriesModal />
        </div>

        {!hideFooter && <Footer />}
      </div>
    </>
  );
};

export default Layout;
