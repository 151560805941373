// import { useSelector } from "react-redux"

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

import type { IndexType } from "@src/types/index.interface";

import useDeviceDetection from "@src/custom-hooks/use-device-detection";

import type { Dispatch, SetStateAction } from "react";

import { useStoryStore } from "@src/zustand/story-store";

import useStories from "@src/custom-hooks/use-stories";

type CProps = {
  indexes: IndexType;
  setIndexes: Dispatch<SetStateAction<IndexType>>;
};

const NavButtons: React.FC<CProps> = ({ indexes, setIndexes }) => {
  //   const stories = useSelector(getStories)
  const { stories } = useStoryStore();

  const device = useDeviceDetection();

  const { goToPrevPost, goToNextPost } = useStories();

  return (
    <>
      <button
        onClick={() => goToPrevPost(indexes, setIndexes)}
        className={`absolute -left-12 z-10 top-1/2 -translate-y-1/2 rounded-full bg-white flex items-center justify-center p-1 ${
          device === "Mobile" ? "hidden" : "block"
        }`}
      >
        <ChevronLeftIcon className="h-8 w-8 text-deep-sky-blue" aria-hidden="true" />
      </button>

      <button
        onClick={() => goToNextPost(indexes, setIndexes, stories)}
        className={`absolute -right-12 z-10 top-1/2 -translate-y-1/2 rounded-full bg-white flex items-center justify-center p-1 ${
          device === "Mobile" ? "hidden" : "block"
        }`}
      >
        <ChevronRightIcon className="h-8 w-8 text-deep-sky-blue" aria-hidden="true" />
      </button>
    </>
  );
};

export default NavButtons;
