import Link from "next/link";

export default function DropdownPanelDesktop({ children, session, showPrescribingLink }) {
  return (
    <div
      className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu"
    >
      <Link href="/editar-perfil" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
        Editar perfil
      </Link>

      <Link href="/solicitud-informacion-medica" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
        Solicitud de información médica
      </Link>

      {(showPrescribingLink || Number(session.user.image.role) >= 5) && (
        <Link href="/ipp" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
          Información Para Prescribir
        </Link>
      )}

      {/* <button data-it-button="rate-this-site" className="bg-navy-light text-white mx-4 my-2 text-xs px-4 py-2 rounded-xl">
        Rate This Site
      </button> */}

      {children}
    </div>
  );
}
