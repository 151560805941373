import {
  BsFillPlayFill,
  BsFillPauseFill,
  BsFillVolumeMuteFill,
  BsFillVolumeUpFill,
} from "react-icons/bs";

import Image from "next/image";

import type { Dispatch, SetStateAction } from "react";

import type { Post } from "@src/types/index.interface";

type CProps = {
  isTracking: boolean;
  post: Post | null;
  setIsTracking: Dispatch<SetStateAction<boolean>>;
  isMuted: boolean;
  toggleMute: () => void;
  playVideo: () => void;
  pauseVideo: () => void;
};

const Controls: React.FC<CProps> = ({
  isTracking,
  post,
  setIsTracking,
  isMuted,
  toggleMute,
  playVideo,
  pauseVideo,
}) => {
  return (
    <>
      {post ? (
        <div className="absolute w-full min-h-[2rem] z-10 top-14 flex justify-between items-end gap-6 px-2">
          <div className="flex gap-2 flex-1 flex-col">
            <div className="flex gap-2 items-center">
              <div className="w-10 h-10">
                <Image
                  src={
                    process.env.IMAGES_URL +
                    "/" +
                    post.secondary_image +
                    "?w=200&h=200"
                  }
                  alt="Post image"
                  className="rounded-full w-10 h-10"
                  width={100}
                  height={100}
                />
              </div>

              <div className="flex-1">
                <p className="text-white font-bold">{post.title}</p>
              </div>
            </div>
          </div>

          <div className="controls w-20 flex">
            {isTracking ? (
              <button
                onClick={() => {
                  pauseVideo();
                }}
              >
                <BsFillPauseFill className="icon-stories-controls" />
              </button>
            ) : (
              <button
                onClick={() => {
                  playVideo();
                  setIsTracking(true);
                }}
              >
                <BsFillPlayFill className="icon-stories-controls" />
              </button>
            )}

            <button
              onClick={() => {
                toggleMute();
              }}
            >
              {isMuted ? (
                <BsFillVolumeMuteFill className="icon-stories-controls" />
              ) : (
                <BsFillVolumeUpFill className="icon-stories-controls" />
              )}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Controls;
