import { useState, useEffect } from "react";
import { XIcon } from "@heroicons/react/outline";
import { parseCookies, setCookie } from 'nookies'

const AppBanner = () => {
  const [showNotification, setShowNotification] = useState(false);
  const text = "Descargue nuestra nueva app Boehringer Click";
  const appUrl = 'https://boehringerclickmx.onelink.me/Xr8p/alwayson'

  const closeNotification = () => {
    setShowNotification(false);
    setCookie(null, 'appNotification', 'true', {
      maxAge: 60 * 60 * 12,
      path: '/',
    })
  };

  useEffect(() => {
    const {appNotification} = parseCookies()
    setShowNotification(!appNotification)
  }, [])
  return (
    <>
      <div className="hidden bg-gradient-to-r from-blue-50 via-blue-100 to-blue-50 md:block">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="font-medium text-bi-green-deep-teal-950">
              <span className="">{text}</span>
              <span className="block sm:ml-2 sm:inline-block">
                <a
                  href='https://boehringerclickmx.onelink.me/Xr8p/displaydesk'
                  target="_blank"
                  className="text-blue-400 underline"
                >
                  {" "}
                  Descargar en Android
                </a>
              </span>
              <span className="block sm:ml-2 sm:inline-block">
                <a href={appUrl} target="_blank" className="text-blue-400 underline">
                  {" "}
                  Descargar en iPhone
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>

      {showNotification && (
        <div className="md:hidden px-2 fixed bottom-2 w-full">
          <div className="bg-gradient-to-b to-bi-green-malachite-500 from-bi-green-deep-teal-950 relative rounded-md text-white py-4 px-8">
            <button onClick={closeNotification} type="button" className="flex p-2 rounded-md absolute right-2 top-0">
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
            <div className="mb-4 flex space-x-4">
              <div>
                <img className="rounded-2xl w-28" src={process.env.IMAGES_URL + "/1024x1024.png"} alt="" />
              </div>
              <div>
                <p>{text}</p>
                <p className="mt-2 text-xs">Educación Médica al alcance de un click</p>
              </div>
            </div>
            <a
              href={appUrl}
              className="w-full text-gray-50 rounded-md text-sm bg-bi-green-malachite-500 px-3 h-11 flex flex-row items-center justify-center"
            >
              Descargar ahora
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default AppBanner;
