import { BsTriangleFill, BsTriangle } from "react-icons/bs";

export const FillPrevMainArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      className="absolute cursor-pointer z-10 transform left-2 top-1/2 -translate-y-1/2 lg:left-auto lg:top-auto lg:bottom-[22px] lg:right-[12%] rotate-[150deg]"
      onClick={onClick}
    >
      <BsTriangleFill className="w-5 h-auto fill-white" />
    </div>
  );
};

export const FillNextMainArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      className="absolute cursor-pointer z-10 transform right-2 top-1/2 -translate-y-1/2  lg:top-auto lg:bottom-6 lg:right-[8%] rotate-90"
      onClick={onClick}
    >
      <BsTriangleFill className="w-5 h-auto fill-white" />
    </div>
  );
};

export const FillPrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div className="absolute cursor-pointer z-10 transform -left-6 top-1/2 -translate-y-1/2 rotate-[150deg]" onClick={onClick}>
      <BsTriangleFill className="w-5 h-auto text-deep-sky-blue" />
    </div>
  );
};

export const FillNextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div className="absolute cursor-pointer z-10 transform -right-6 min-[501px]:right-0 top-1/2 -translate-y-1/2 rotate-90" onClick={onClick}>
      <BsTriangleFill className="w-5 h-auto text-deep-sky-blue" />
    </div>
  );
};

export const PrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div className="absolute cursor-pointer z-10 transform -left-8 top-1/2 -translate-y-1/2 rotate-[150deg]" onClick={onClick}>
      <BsTriangle className="w-5 h-auto fill-white" />
    </div>
  );
};

export const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div className="absolute cursor-pointer z-10 transform -right-4 top-1/2 -translate-y-1/2 rotate-90" onClick={onClick}>
      <BsTriangle className="w-5 h-auto fill-white" />
    </div>
  );
};
