import { create } from "zustand";

import type {
  Post,
  Story,
  GroupStoriesType,
  IndexType,
} from "@src/types/index.interface";

type Store = {
  openStoryModal: boolean;
  currentPosts: Post[] | null;
  stories: GroupStoriesType | null;
  initIndexes: IndexType | null;
  storiesInteractions: Story[];
  postInteractions: Post[];

  setOpenStoryModal: (status: boolean) => void;
  setCurrentPosts: (posts: Post[] | null) => void;
  setStories: (stories: GroupStoriesType | null) => void;
  setInitIndexes: (indexes: IndexType | null) => void;
  addStoriesInteractions: (story: Story) => void;
  resetStoriesInteractions: () => void;
  addPostInteractions: (post: Post) => void;
  resetPostInteractions: () => void;
};

export const useStoryStore = create<Store>((set) => ({
  openStoryModal: false,
  currentPosts: null,
  stories: null,
  initIndexes: null,
  storiesInteractions: [],
  postInteractions: [],

  setOpenStoryModal: status => set(() => ({ openStoryModal: status })),
  setCurrentPosts: (posts) => set(() => ({ currentPosts: posts })),
  setStories: (stories) => set(() => ({ stories })),
  setInitIndexes: (indexes) => set(() => ({ initIndexes: indexes })),
  addStoriesInteractions: (story) => set((state) => ({ storiesInteractions: [...state.storiesInteractions, story] })),
  resetStoriesInteractions: () => set(() => ({ storiesInteractions: [] })),
  addPostInteractions: (post) => set((state) => ({ postInteractions: [...state.postInteractions, post] })),
  resetPostInteractions: () => set(() => ({ postInteractions: [] })),

}));
