import { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useStoryStore } from "@src/zustand/story-store";

import { XIcon } from "@heroicons/react/outline";

import useStories from "@src/custom-hooks/use-stories";

// import { useMutation } from "@apollo/client"

import useDetectSwipe from "@src/custom-hooks/use-detect-swipe";

import Controls from "./controls";
import NavButtons from "./nav-buttons";
import ProgressBars from "./progress-bars";

import MuxPlayer from "@mux/mux-player-react";

import { useSession } from "next-auth/client"

import UseHookWithRefCallback from "@src/custom-hooks/use-hook-with-ref-callback";

import type { MuxPlayerRefAttributes } from "@mux/mux-player-react";

// import useTracking from "@src/hooks/use-trackings"

// import { MarkStoryViewDropoutDocument } from "@src/types/generated-graphql/apollo-client"

const StoriesModal: React.FC = () => {
  //   const open = useSelector(getOpenStoryModal)
  //   const stories = useSelector(getStories)

  const [session] = useSession()

  const isFirstTracking = useRef(true);

  const {
    openStoryModal,
    setOpenStoryModal,
    stories,
    setStories,
    resetPostInteractions,
    resetStoriesInteractions,
  } = useStoryStore();

  //   const [callMarkDropout] = useMutation(MarkStoryViewDropoutDocument)

  const {
    indexes,
    setIndexes,
    animation,
    isTracking,
    post,
    onVideoEnd,
    setIsTracking,
    addEventListeners,
    removeEventListeners,
    setAnimation,
    setPost,
    setIsMuted,
    isMuted,
    toggleMute,
    playVideo,
    pauseVideo,
    goToPrevPost,
    goToNextPost,
    goToPrevStory,
    goToNextStory,
  } = useStories();

  const swipeHandlers = useDetectSwipe({
    onSwipedLeft: () => goToNextPost(indexes, setIndexes, stories),
    onSwipedRight: () => goToPrevPost(indexes, setIndexes),
  });

  const playerRef = UseHookWithRefCallback<
    null | MuxPlayerRefAttributes | undefined
  >(
    (node) => addEventListeners(node),
    (node) => removeEventListeners(node)
  );

  //   const { startTracking: startStoryTracking, stopTracking: stopStoryTracking } =
  //     useTracking({
  //       type: "story",
  //       story: stories?.get(indexes.postId!)?.[indexes.story],
  //     })

  //   const { startTracking: startPostTracking, stopTracking: stopPostTracking } =
  //     useTracking({
  //       type: "post",
  //       post,
  //     })

  useEffect(() => {
    const animations = document.getElementsByClassName("animations");
    for (const animation of animations) {
      animation.classList.remove("animate-progress-bar-2");
    }
  }, [indexes]);


  useEffect(() => {
    if (openStoryModal) {
      isFirstTracking.current = true;
    } else {
      resetStoriesInteractions();
      resetPostInteractions();
      setIsTracking(false);
      setStories(null);
      setIndexes({
        story: 0,
        group: 0,
        postId: null,
      });
      setAnimation(false);
      setPost(null);
      setIsMuted(true);
      isFirstTracking.current = true;
    }
  }, [openStoryModal]);

  // 👇 for tracking and save it into BD
  useEffect(() => {
    if (isTracking) {
      // startStoryTracking()
      // startPostTracking()

      if (isFirstTracking.current) {
        toggleMute(false);
      }

      // isFirstTracking.current = false
    } else {
      // stopStoryTracking()
      // stopPostTracking()
    }
  }, [isTracking]);

  const refDiv = useRef(null);

  return (
    <Transition.Root show={openStoryModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-999999"
        onClose={() => {
          setOpenStoryModal(false);
        }}
        // onClose={() => dispatch(setOpenStoryModal(false))}
        static
        initialFocus={refDiv}
        open={true}
      >
        <div className="fixed inset-0  w-screen">
          <div className="flex h-screen justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="w-full h-full my-8  relative backdrop-story">
                {/* @ts-ignore */}
                <div
                  {...swipeHandlers}
                  ref={refDiv}
                  className={`sm:max-w-md w-full h-full relative sm:mx-auto py-3 bg-black ${
                    animation ? "animate-fade-in" : ""
                  }`}
                >
                  <div className="absolute right-4 top-4 z-20">
                    <button
                      type="button"
                      className="text-white"
                      onClick={() => {
                        setOpenStoryModal(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon
                        className="h-6 w-6 text-white "
                        aria-hidden="true"
                      />
                    </button>
                  </div>

                  <NavButtons indexes={indexes} setIndexes={setIndexes} />

                  <div
                    className="absolute h-full w-1/3 z-10 top-0 "
                    onClick={() => goToPrevStory(indexes, setIndexes)}
                  />

                  <div
                    className="absolute h-full w-2/3 z-10 top-0 left-1/3 "
                    onClick={() => {
                      goToNextStory(indexes, setIndexes, stories);
                      //   callMarkDropout({
                      //     variables: {
                      //       storyId: stories?.get(indexes.postId!)?.[
                      //         indexes.story
                      //       ]!.id,
                      //     },
                      //   })
                    }}
                  />

                  {stories && indexes.postId ? (
                    <>
                      <ProgressBars
                        stories={stories}
                        indexes={indexes}
                        isTracking={isTracking}
                        post={post}
                      />

                      <Controls
                        isTracking={isTracking}
                        post={post}
                        setIsTracking={setIsTracking}
                        isMuted={isMuted}
                        toggleMute={toggleMute}
                        playVideo={playVideo}
                        pauseVideo={pauseVideo}
                      />

                      <MuxPlayer
                        ref={playerRef}
                        className="absolute inset-0 w-full h-full"
                        streamType="on-demand"
                        playbackId={
                          stories.get(indexes.postId!)![indexes.story]
                            ?.playback_id
                        }
                        autoPlay={true}
                        muted
                        onEnded={onVideoEnd}
                        onCanPlay={() => setIsTracking(true)}
                        metadata={{
                          video_id: stories.get(indexes.postId!)![indexes.story]
                            ?.id,
                          video_title: post?.title,
                          // @ts-ignore
                          viewer_user_id: session.user.image.user_id
                        }}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default StoriesModal;
