import type { LiveEvent, Session } from "@src/types/index.interface";

import AppBanner from "@components/layout/app-banner";

import { useRouter } from "next/router";

import { useState, useEffect } from "react";

import { getComponent } from "../../src/utils.js";

import MobileNav from "./mobile-nav";
import DesktopNav from "./desktop-nav";

import algoliasearch from "algoliasearch/lite";

import api from "@src/api/index.js";

type CProps = {
  session: Session;
  children: JSX.Element[];
  liveEvents: LiveEvent[];
};

const Header: React.FC<CProps> = ({ session, children, liveEvents }) => {
  const [adminApiKey, setAdminApiKey] = useState("");

  const [searchState, setSearchState] = useState("");
  const [modalSearchState, setModalSearchState] = useState(false);

  const algoliaClient = algoliasearch("GVVGZM7IIY", adminApiKey);

  const getAdminApiKey = async () =>
    setAdminApiKey(
      await (new api.GetAdminApiKey() as any).doQuery(
        null,
        session.user?.image?.access_token
      )
    );

  useEffect(() => {
    getAdminApiKey();
  }, []);

  return (
    <header className="w-full top-0 z-50 fixed">
      <AppBanner />

      <MobileNav
        liveEvents={liveEvents}
        session={session}
        setModalSearchState={setModalSearchState}
        modalSearchState={modalSearchState}
        searchState={searchState}
        algoliaClient={algoliaClient}
      >
        {getComponent(children, "DropdownPanelMobile")}
      </MobileNav>

      <DesktopNav
        liveEvents={liveEvents}
        session={session}
        setModalSearchState={setModalSearchState}
        modalSearchState={modalSearchState}
        searchState={searchState}
        algoliaClient={algoliaClient}
      >
        {getComponent(children, "DropdownPanelDesktop")}
      </DesktopNav>
    </header>
  );
};

export default Header;
