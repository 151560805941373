import MenuIcon from "../../public/img/menu.svg";

import Link from "next/link";
import Image from "next/image";

import GreenLogo from "../../public/img/green-logo-2.png";
import Touch from "../../public/img/Boehringer_Standalone_Touch_RGB_Accent-Green.png";
import LeftChevronIcon from "../../public/img/chevron-left-solid.svg";

import SearchIcon from "../../public/img/search.svg";

import { useState } from "react";

import { Transition } from "@headlessui/react";

import type { Dispatch, PropsWithChildren, SetStateAction } from "react";

import LiveButton from "../liveButton";

import type { LiveEvent, Session } from "@src/types/index.interface";

import ModalSearch from "../modals/ModalSearch";

import { SearchClient } from "algoliasearch/lite";

import { useRouter } from "next/router";

type CProps = {
  liveEvents: LiveEvent[];
  session: Session;
  setModalSearchState: Dispatch<SetStateAction<boolean>>;
  modalSearchState: boolean;
  searchState: string;
  algoliaClient: SearchClient;
};

export const MobileNav = (props: PropsWithChildren<CProps>) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [searchBarState, setSearchBarState] = useState(false);

  const { asPath } = useRouter();

  return (
    <>
      <nav className="bg-bi-green-deep-teal-950 h-16 border-b border-bi-green-deep-teal-900 px-2 relative flex space-x-2 items-center justify-between xl:hidden">
        {searchBarState ? (
          <>
            <div>
              <LeftChevronIcon
                className="block lg:hidden h-8 w-auto"
                style={{ fill: "#FFFFFF" }}
                onClick={() => setSearchBarState(false)}
              />
            </div>

            <div className="flex-1">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="">
                <ModalSearch
                  algoliaClient={props.algoliaClient}
                  searchState={props.searchState}
                  closeModal={() => props.setModalSearchState(false)}
                  modalSearchState={props.modalSearchState}
                  setModalSearchState={props.setModalSearchState}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {/* 1 */}
            <div className="flex items-shrink px-2 lg:px-0">
              <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                <MenuIcon />
              </button>
            </div>

            {/* 2 */}
            <div className="flex-1 flex items-center justify-between">
              <Link href="/" className="inline">
                <Image src={GreenLogo} alt="" className="h-auto w-24" />
              </Link>

              {!!props.liveEvents.length && asPath === "/home" && (
                <LiveButton liveEvents={props.liveEvents} />
              )}

              <div className="flex flex-row items-center">
                <button
                  data-it-button="rate-this-site"
                  className="hidden md:block bg-bi-green-malachite-500 text-bi-green-malachite-900 w-fit mx-4 my-2 text-sm px-4 py-2 rounded-md"
                >
                  Evalúe nuestro sitio
                </button>

                <Link href="/" className="flex items-center">
                  <Image src={Touch} alt="" className="h-auto w-6 sm:w-8" />
                  <span className="text-bi-green-logo text-lg sm:text-xl">
                    Click
                  </span>
                </Link>
              </div>
            </div>

            {/* 3 */}
            <div>
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false"
              >
                <SearchIcon
                  className="block h-6 w-6 text-gray-100"
                  onClick={() => setSearchBarState(true)}
                />
              </button>
            </div>
          </>
        )}
      </nav>

      <Transition
        show={isMobileMenuOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {props.children}
      </Transition>
    </>
  );
};

export default MobileNav;
