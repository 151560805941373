import type {
  Post,
  IndexType,
  GroupStoriesType,
} from "@src/types/index.interface";

import type { Dispatch, SetStateAction } from "react";

import { useState, useEffect, useRef } from "react";

// import { useSelector, useDispatch } from "react-redux"

import { getPostById } from "@src/utilities/slider-fns";

import type MuxPlayerElement from "@mux/mux-player";

import { useStoryStore } from "@src/zustand/story-store";

// import {
//   MarkStoryViewCompletionDocument,
//   IncrementPostViewStoriesSeenDocument,
// } from "@src/types/generated-graphql/apollo-client"

// import { useMutation } from "@apollo/client"

const useStories = () => {
  const { stories, setOpenStoryModal, currentPosts, initIndexes } =
    useStoryStore();

  const storyIndexRef = useRef(0);

  //   const [callMarkCompletion] = useMutation(MarkStoryViewCompletionDocument)
  //   const [callIncrementPostViewStoriesSeen] = useMutation(
  //     IncrementPostViewStoriesSeenDocument
  //   )

  const [isTracking, setIsTracking] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [animation, setAnimation] = useState(false);
  const [post, setPost] = useState<Post | null>(null);
  const [indexes, setIndexes] = useState<IndexType>({
    story: 0,
    group: 0,
    postId: null,
  });

  const getPrevPostId = (indexes: IndexType) =>
    Array.from(stories!.keys())[indexes.group - 1];

  const getNextPostId = (indexes: IndexType) =>
    Array.from(stories!.keys())[indexes.group + 1];

  const onVideoEnd = () => {
    if (stories) {
      setIsTracking(false);

      //   callMarkCompletion({
      //     variables: {
      //       storyId: stories?.get(indexes.postId!)?.[indexes.story]!.id,
      //     },
      //   });

      //   callIncrementPostViewStoriesSeen({
      //     variables: {
      //       postId: post!.id,
      //     },
      //   });

      goToNextStory(indexes, setIndexes, stories);
    }
  };

  const getMuxPlayer = () =>
    document.querySelector("mux-player") as MuxPlayerElement;

  const playVideo = () => getMuxPlayer().play();

  const pauseVideo = () => getMuxPlayer().pause();

  const toggleMute = (isMuted: boolean | null = null) => {
    const muxPlayer = getMuxPlayer();
    muxPlayer.muted = isMuted === null ? !muxPlayer.muted : isMuted;
    setIsMuted(muxPlayer.muted);
  };

  const addEventListeners = (
    muxPlayer: MuxPlayerElement | null | undefined
  ) => {
    if (muxPlayer) {
      muxPlayer.addEventListener("pause", () => {
        setIsTracking(false);
      });

      muxPlayer.addEventListener("ended", () => {
        setIsTracking(false);
      });
    }
  };

  const removeEventListeners = (
    muxPlayer: MuxPlayerElement | null | undefined
  ) => {
    if (muxPlayer) {
      muxPlayer.removeEventListener("pause", () => {
        setIsTracking(false);
      });

      muxPlayer.removeEventListener("ended", () => {
        setIsTracking(false);
      });
    }
  };

  const goToPrevPost = (
    indexes: IndexType,
    setIndexes: Dispatch<SetStateAction<IndexType>>
  ) => {
    if (indexes.group === 0) {
      setOpenStoryModal(false);
    } else {
      setIndexes({
        postId: getPrevPostId(indexes),
        story: 0,
        group: indexes.group - 1,
      });
    }
  };

  const goToNextPost = (
    indexes: IndexType,
    setIndexes: Dispatch<SetStateAction<IndexType>>,
    stories: GroupStoriesType | null
  ) => {
    if (stories!.size - 1 === indexes.group) {
      setOpenStoryModal(false);
    } else {
      setIndexes({
        postId: getNextPostId(indexes),
        story: 0,
        group: indexes.group + 1,
      });
    }
  };

  const goToPrevStory = (
    indexes: IndexType,
    setIndexes: Dispatch<SetStateAction<IndexType>>
  ) => {
    if (indexes.story !== 0) {
      setIndexes((prevIndexes) => ({
        ...prevIndexes,
        story: indexes.story - 1,
      }));
      return;
    }

    if (indexes.group === 0) {
      setOpenStoryModal(false);
      return;
    }

    const postId = getPrevPostId(indexes);
    const prevPost = stories!.get(postId);

    setIndexes({
      postId,
      story: prevPost!.length - 1,
      group: indexes.group - 1,
    });
  };

  const goToNextStory = (
    indexes: IndexType,
    setIndexes: Dispatch<SetStateAction<IndexType>>,
    stories: GroupStoriesType | null
  ) => {
    if (indexes.story !== stories?.get(indexes.postId!)?.length! - 1) {
      setIndexes((prevIndexes) => ({
        ...prevIndexes,
        story: indexes.story + 1,
      }));

      return;
    }

    if (indexes.group <= stories?.size! - 1) {
      if (indexes.group === stories?.size! - 1) {
        setOpenStoryModal(false);
      } else {
        setIndexes({
          postId: getNextPostId(indexes),
          story: 0,
          group: indexes.group + 1,
        });
      }
    }
  };

  useEffect(() => {
    storyIndexRef.current = indexes.story;
    setIsTracking(false);
  }, [indexes.story]);

  useEffect(() => {
    setIsTracking(false);
  }, [indexes.group]);

  // 👇 on group change by arrow click or video end
  useEffect(() => {
    if (stories) {
      // setIndexes((prevIndexes) => ({
      //   ...prevIndexes,
      //   postId: getStoryKey(indexes.group, stories),
      //   story: 0,
      // }))

      setAnimation(true);

      setTimeout(() => {
        setAnimation(false);
      }, 500);
    }
  }, [indexes.group]);

  useEffect(() => {
    if (currentPosts && indexes.postId) {
      setPost(getPostById(currentPosts, indexes.postId));
    }
  }, [indexes.postId]);

  // 👇 when modal is opened
  useEffect(() => {
    if (stories && initIndexes) {
      setIndexes(initIndexes);
      setAnimation(false);
    }
  }, [stories, initIndexes]);

  return {
    isTracking,
    setIsTracking,
    animation,
    setAnimation,
    post,
    setPost,
    indexes,
    setIndexes,
    onVideoEnd,
    playVideo,
    pauseVideo,
    getMuxPlayer,
    addEventListeners,
    toggleMute,
    isMuted,
    setIsMuted,
    removeEventListeners,
    goToPrevPost,
    goToNextPost,
    goToPrevStory,
    goToNextStory,
  };
};

export default useStories;
