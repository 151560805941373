export const blackListedEmails = [
  "fzaventas@boehringer-ingelheim.com",
  "fzadeventas@boehringer-ingelheim.com",
  "Fzaventas@boehringer-ingelheim.com",
  "fezaventas@boehringer-ingelheim.com",
  "fza-ventas@boehringer-ingelheim.com",
];

export const dashboardMenuRoutes = [
  {
    url: "/dashboard",
    icon: (
      <svg
        className="text-bright-sky-blue group-hover:text-white h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        />
      </svg>
    ),
    title: "Inicio",
  },

  {
    url: "/dashboard/eventos",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="text-bright-sky-blue group-hover:text-white h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
      </svg>
    ),
    title: "Eventos",
  },

  {
    url: "/dashboard/faq",
    icon: (
      <svg
        className="text-bright-sky-blue group-hover:text-white h-6 w-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
    title: "FAQs",
  },
];
