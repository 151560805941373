import { useState } from "react";
import LiveIcon from "../public/img/live.svg";
import { useRouter } from "next/router";
import ModalLiveEvents from "./modals/ModalLiveEvents";

export default function LiveButton({ liveEvents }) {
  const router = useRouter();
  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const goToEvent = () => {
    if (liveEvents.length === 1) router.push(`/live/${liveEvents[0].slug}`);

    if (liveEvents.length > 1) setModalState(true);
  };

  return (
    <>
      <button
        onClick={goToEvent}
        className="text-bi-green-malachite-900 rounded-full text-xs whitespace-nowrap bg-bi-green-malachite-500 px-2 py-1 flex flex-row items-center gap-1 justify-center sm:text-sm sm:px-4 py-2"
      >
        <LiveIcon className="w-5 h-5 fill-current text-bi-green-malachite-900" />
        <span>En vivo ahora</span>
      </button>

      {modalState && (
        <ModalLiveEvents liveEvents={liveEvents} toggleModal={toggleModal} />
      )}
    </>
  );
}
